import useTranslateFunction from "@hooks/useTranslateFunction"
import { useEffect, useMemo, useState } from "react"
import Slider from "rc-slider"
import { new_plans } from "static_config/plans"
import { RadioGroup } from "@headlessui/react"
import { classNames } from "lib/classNames"
import Link from "next/link"
import { WhatsAppLink } from "./WhatsAppWidget"
import "rc-slider/assets/index.css"
import posthog from "posthog-js"

export function PricingSlider({
  isSubscribePage,
  onPickPlan,
}: {
  isSubscribePage?: boolean
  onPickPlan?: (priceId: string) => void
}) {
  const plans = new_plans

  const [slideValue, setSlideValue] = useState(1)
  const selectedPlan = plans[slideValue - 1]

  const { t } = useTranslateFunction()
  const frequencies = useMemo(
    () => [
      {
        value: "monthly",
        label: t("pricing_Precio mensual"),
        priceSuffix: t("pricing_/mes"),
      },
      {
        value: "yearly",
        label: t("pricing_Precio anual"),
        priceSuffix: t("pricing_/año"),
      },
    ],
    []
  )
  const [frequency, setFrequency] = useState(frequencies[0])
  const frequencyName = {
    yearly: t("pricing_al año", "al año"),
    monthly: t("pricing_al mes", "al mes"),
  }

  useEffect(() => {
    setTimeout(() => {
      posthog.reloadFeatureFlags()
    }, 100)
  }, [])

  return (
    <div
      id="precio"
      className={`${
        isSubscribePage ? "py-0" : "py-24"
      } mx-auto w-full max-w-6xl px-4  md:px-6`}
    >
      {!isSubscribePage && (
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            {t("pricing_Precios")}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 ">
            {t("pricing_Precios que se adaptan al tamaño de tu negocio")}
          </p>
        </div>
      )}

      {!isSubscribePage && (
        <div className={`${!isSubscribePage ? "mt-12" : ""} text-center`}>
          <h3 className="text-bold text-2xl">
            {t("pricing_¿Cuántas citas tienes al mes?")}
          </h3>
          <p className=" mt-2 text-gray-400">
            {t(
              "pricing_Escoge el plan en base a la cantidad de citas que tienes al mes"
            )}
          </p>
        </div>
      )}

      <div>
        <PricingRange
          slideValue={slideValue}
          onPlanChange={setSlideValue}
        ></PricingRange>
      </div>
      <div className="relative mt-24 flex justify-center">
        <RadioGroup
          value={frequency}
          onChange={(value) => {
            setFrequency(value)
          }}
          className=" relative grid grid-cols-2 gap-x-1 rounded-full bg-white p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
        >
          <div className="absolute -top-8 right-12 sm:-top-6 sm:right-0">
            <img
              alt="arrow"
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTUiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1NSAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC4yIiBkPSJNMC4yNzY3NjYgMi42MjA3MkMyLjAxNzA0IDAuNjU4Mjg5IDQuMzEwMjggMC4yOTUwODYgNi40NDYzNSAwLjIwMTA3NkM5LjQxNTkgMC4xMDUzNTIgMTIuMzk5NCAwLjI5MTI2NyAxNS4yMjU4IDAuNzQ2MzY0QzI4LjE1MTYgMy4xNTIzNiAzNy42NjMzIDkuNjc1MTIgNDQuMDgzMiAxOS41NTg2QzQ1LjcwNDggMjIuMDc3NSA0Ni45MjM2IDI0Ljg3ODggNDguNDU0NCAyNy44NTg3QzUwLjkyNTcgMjUuMzU3MSA1MS40OTQ5IDIxLjkzNzUgNTMuODk5OSAxOS4yNDRDNTQuNzE0NyAyMC4zOTQ1IDU0LjI1NzIgMjEuNDgzNyA1My45MDQ1IDIyLjM5MzVDNTIuNDQxMyAyNi4xMjIxIDUwLjkwMzYgMjkuODc2NiA0OS4yNjkzIDMzLjU5MjhDNDguNDY3MyAzNS4zNzQgNDcuNTQzIDM1LjgzNjggNDUuODAzOCAzNS4xNDkxQzQyLjE1NDUgMzMuNzYxIDM5LjQ0NTcgMzEuNDc0OSAzNy43NzQgMjguMzI4OEMzNy43Mjk4IDI4LjIwMDggMzcuODg2OSAyNy45MzE3IDM4LjA3NDMgMjcuNTA4OEM0MS41MjEyIDI3LjEwNDggNDEuOTkyNiAzMC44ODA3IDQ1LjE5MTUgMzEuMjA3QzQ0Ljg1NzQgMjYuMzgxIDQyLjUwMzYgMjIuNDY4MSAzOS43NDcxIDE4LjgzNzdDMzYuODQ5NyAxNS4wNDEyIDMzLjU3MTYgMTEuNTkxMiAyOS4zMzA5IDguOTc1MjhDMjUuMTg2OCA2LjM5NzYgMjAuNTQwOSA0Ljc4MTE3IDE1LjU4OSAzLjQ4NTQ1QzEwLjY4OTQgMi4xIDUuNjIyMTggMS45MTgzNyAwLjI3Njc2NiAyLjYyMDcyWiIgZmlsbD0iIzBEMTkyNyIvPgo8L3N2Zz4K"
              className="w-6 -scale-x-100 pt-1.5"
            ></img>
            <span className="absolute -top-4 left-4 w-28 text-xs text-gray-400">
              {t("pricing_2 meses gratis con el plan anual")}
            </span>
          </div>
          <RadioGroup.Label className="sr-only">
            Payment frequency
          </RadioGroup.Label>
          {frequencies.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option}
              className={({ checked }) =>
                classNames(
                  checked ? "bg-indigo-600 text-white" : "text-gray-500",
                  "cursor-pointer rounded-full px-2.5 py-1"
                )
              }
            >
              <span>{option.label}</span>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
      {/* Pricing table component */}
      <div>
        {slideValue < 7 ? (
          <PriceDescriptionCard
            selectedPlan={selectedPlan}
            frequency={frequency}
            onPickPlan={onPickPlan}
            frequencyName={frequencyName}
          ></PriceDescriptionCard>
        ) : (
          <EntreprisePriceDescriptionCard />
        )}

        {/* if you want to bring second column add: lg:max-w-[728px] lg:grid-cols-2 */}
      </div>
    </div>
  )
}

function PriceDescriptionCard({
  selectedPlan,
  frequency,
  onPickPlan,
  frequencyName,
}: any) {
  const { t } = useTranslateFunction()

  return (
    <div className="mx-auto grid max-w-sm items-start gap-6 pt-10">
      {/* Pricing tab 1 */}
      <div className="h-full rounded-lg border bg-white shadow-xl">
        <div className="relative flex h-full flex-col p-6 pb-10">
          <div className="mb-5">
            {/* <div className="mb-1 font-semibold text-slate-900">Starter</div> */}
            <div className="mb-5 text-lg text-slate-500">
              {t(
                "pricing_Plan de {{maxMessages}} citas al mes",
                { maxMessages: selectedPlan.maxNumberOfMessagesPerMonth },
                { default: "Plan de {{maxMessages}} citas al mes" }
              )}
            </div>
            <div className="mb-2 inline-flex items-baseline">
              <span className="text-3xl font-bold text-slate-900">$</span>
              <span
                className="text-4xl font-bold text-slate-900"
                x-text="prices[value].plans.starter"
              >
                {selectedPlan.currency === "mxn"
                  ? selectedPlan.price[frequency.value].toLocaleString("mx")
                  : selectedPlan.price[frequency.value]}
              </span>
              <span className="font-medium text-slate-500">
                {selectedPlan.currency === "mxn"
                  ? "pesos mexicanos"
                  : t("pricing_dólares")}
                {" " + frequencyName[frequency.value]}
              </span>
            </div>
            {onPickPlan ? (
              <button
                className="inline-flex w-full justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 transition-colors duration-150 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300"
                onClick={() =>
                  onPickPlan(selectedPlan.stripePriceId[frequency.value])
                }
              >
                {t("pricing_Comprar plan", "Comprar plan")}
              </button>
            ) : (
              <Link
                className="flex w-full flex-col items-center justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-2xl font-medium text-white shadow-sm shadow-indigo-950/10 transition-colors duration-150 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300"
                href={`/signup`}
              >
                {t("common_Empieza gratis")} <br />{" "}
                <span className="mt-1 block text-xs">
                  ({t("common_No hace falta tarjeta")})
                </span>
              </Link>
            )}
          </div>
          <div className="mb-4 text-sm font-medium text-slate-900">
            {t("pricing_Incluye", "Incluye")}
          </div>
          <ul className="grow space-y-3 text-sm text-slate-600">
            <li className="flex items-center">
              <svg
                className="mr-3 h-3 w-3 shrink-0 fill-emerald-500"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>
                {selectedPlan.maxNumberOfMessagesPerMonth}{" "}
                {t("pricing_citas al mes", "citas al mes")}
              </span>
            </li>
            <li className="flex items-center">
              <svg
                className="mr-3 h-3 w-3 shrink-0 fill-emerald-500"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>
                {t(
                  "pricing_3 mensajes por cita: confirmación, recordatorio y post cita.",
                  "3 mensajes por cita: confirmación, recordatorio y post cita."
                )}
              </span>
            </li>
            <li className="flex items-center">
              <svg
                className="mr-3 h-3 w-3 shrink-0 fill-emerald-500"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>
                {t(
                  "pricing_Calendario de Confirmafy o Google Calendar",
                  "Calendario de Confirmafy o Google Calendar"
                )}
              </span>
            </li>
            <li className="flex items-center">
              <svg
                className="mr-3 h-3 w-3 shrink-0 fill-emerald-500"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>
                {t("pricing_Soporte por WhatsApp", "Soporte por WhatsApp")}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function EntreprisePriceDescriptionCard() {
  const { t } = useTranslateFunction()

  return (
    <div className="mx-auto grid max-w-sm items-start gap-6 pt-10">
      <div className="h-full rounded-lg bg-white">
        <div className="relative flex h-full flex-col p-6 pb-10">
          <div>
            <h4 className="mb-5 text-center text-2xl  ">
              {t("pricing_¿Más de 500 citas al mes?")}
            </h4>
            <p>{t("pricing_ContactarForCustomPlan")}</p>

            <WhatsAppLink
              className={
                "mt-8 block w-full max-w-md rounded-md border border-transparent bg-indigo-50 px-6 py-3 text-center font-medium text-indigo-700 hover:bg-indigo-100"
              }
              message={t(
                "pricing_Hola, mi negocio tiene más de 500 citas al mes. Quiero información sobre sus planes enterprise."
              )}
            >
              {t("pricing_Contactar")}
            </WhatsAppLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export function PricingRange({
  onPlanChange,
  slideValue,
}: {
  onPlanChange?: (slideValue: number) => void
  slideValue: number
}) {
  const { t } = useTranslateFunction()

  const marks = {
    1: "50",
    2: "100",
    3: "200",
    4: "300",
    5: "400",
    6: "500",
    7: {
      label: <span className="block w-14 ">{t("pricing_500+")}</span>,
    },
  }

  return (
    <div className="mx-auto my-10 max-w-xl px-4">
      <Slider
        min={1}
        max={7}
        value={slideValue}
        marks={marks}
        step={null}
        onChange={onPlanChange}
      />
    </div>
  )
}
